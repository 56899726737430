import axios from "axios";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

const responseErrorHandler = async (error: any) => {
    if (error.response.status === 401) {
        if (localStorage !== null) {
            localStorage.clear();
        }
        return (
            window.location.pathname !== `/login` && (window.location.href = `/login`)
        );
    }

    return Promise.reject(error);
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: headers,
    withCredentials: true,
});

api.interceptors.request.use(
    async (request) => {
        let token;
        token = (await localStorage.getItem("@clientProposal:auth")) || "";

        if (token) {
            if (request.headers) {
                request.headers.Authorization = `Bearer ${token.replaceAll('"', '')}`;
            }
        }

        if (request.headers) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window?.location?.hostname?.includes('propostahomolog') || window?.location?.hostname?.includes('urlhomolog.dinheirosim.com.br')) {
                window?.location?.hostname?.includes('propostahomolog.bevioficial.com.br') ?
                    request.headers['app-origem'] = 'domushomolog' :
                    request.headers['app-origem'] = 'linkhomolog';
            } else {
                window?.location?.hostname?.includes('proposta.bevioficial.com.br') ?
                    request.headers['app-origem'] = 'domus' :
                    request.headers['app-origem'] = 'link';
            }
        }

        return request;
    },
    (error) => console.log(error)
);

api.interceptors.response.use(
    async (request) => {
        return request;
    },
    (error) => responseErrorHandler(error)
);

export default api;
