import api from "../../Api";
import {BaseService} from "../../BaseService";
import {RequestSimulacaoBB} from "../../../models/products/BB/RequestSimulacaoBB";

export class BBService extends BaseService {
    async getUrl(payload: { guid: string, cpf: string, birthday: string }) {
        try {
            return this.handleResponse(await api.post('api/bb/simulation/url', payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async tokenBB(params: any) {
        try {
            const {code, state, authId} = params;
            return this.handleResponse(await api.post('api/bb/auth/token', {code, state, authId}));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async contratoPreview(params: any) {
        try {
            return this.handleResponse(await api.post('api/bb/contrato/preview', params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async contratar(previewId: number, indicadorUtilizacaoChequeEspecial: boolean, indicadorDebitoParcelaAtraso: boolean) {
        try {
            return this.handleResponse(await api.post('api/bb/contrato', {
                previewId,
                indicadorUtilizacaoChequeEspecial,
                indicadorDebitoParcelaAtraso
            }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getProdutos(authId: number) {
        try {
            return this.handleResponse(await api.post('api/bb/pre', {authId}));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getSimulacao(payload: RequestSimulacaoBB) {
        try {
            return this.handleResponse(await api.post('api/bb/pre/simulacao', payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}