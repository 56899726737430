import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import FormatDate from '../../../../utils/FormatDate';
import FormatMoney from '../../../../utils/FormatMoney';
import './DetailsProposalBB.scss';

interface DetailsProposalBBProps {
  proposal: any;
}

const DetailsProposalBB: FC<DetailsProposalBBProps> = ({ proposal }) => {
  return (
    <Grid container className="DetailsProposalBB" data-testid="DetailsProposalBB">
      {proposal && (
        <>
          <Grid item xs={12} className="details" mt={1}>
            <Typography className="titleDest">Valor liberado</Typography>
            <Typography className="fieldValueDest">
              {FormatMoney(proposal.valorLiberado)}
            </Typography>
          </Grid>

          <Grid container spacing={1} className="details">
            <Grid item xs={4}>
              <Typography className="title">Valor<br />Parcela</Typography>
              <Typography className="fieldValue">
                {FormatMoney(proposal?.valorParcela)}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Qtd.<br />Parcelas</Typography>
              <Typography className="fieldValue">
                {proposal?.quantidadeParcelas}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Primeiro<br />Vcto.</Typography>
              <Typography className="fieldValue">
                {FormatDate(proposal?.dataVencimentoPrimeiraParcela)}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Valor<br />IOF</Typography>
              <Typography className="fieldValue">
                {FormatMoney(proposal?.valorIOF)}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Taxa<br />Mensal</Typography>
              <Typography className="fieldValue">
                {`${proposal?.taxa || ''}%`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">CET<br />Mensal</Typography>
              <Typography className="fieldValue">
                {`${proposal?.taxaCETMensal || ''}%`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Valor<br />Base CET</Typography>
              <Typography className="fieldValue">
                {`${FormatMoney(proposal?.valorBaseCET)}`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Taxa<br />Anual</Typography>
              <Typography className="fieldValue">
                {`${proposal?.taxaAnual || ''}%`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">CET<br />Anual</Typography>
              <Typography className="fieldValue">
                {`${proposal?.taxaCETAnual || ''}%`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Total<br />Financiado</Typography>
              <Typography className="fieldValue">
                {`${FormatMoney(proposal?.valorTotalFinanciado)}`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Total<br />Juros</Typography>
              <Typography className="fieldValue">
                {`${FormatMoney(proposal?.valorTotalJuros)}`}
              </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography className="title">Valor<br />Bruto</Typography>
              <Typography className="fieldValue">
                {`${FormatMoney(proposal?.valorParcela * proposal?.quantidadeParcelas)}`}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid >
  );
}

export default DetailsProposalBB;
