import { Grid, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import './SkeletonSimulationCard.scss';

interface Props {
}

const SkeletonSimulationCard: FC<Props> = () => (
    <div className="SkeletonSimulationCard" data-testid="SkeletonSimulationCard">
        <Grid container>
            <Grid item xs={12} className="header">
                <Typography variant="h4">
                    <Skeleton variant="text" width={200} height={30} />
                </Typography>
            </Grid>

            <Grid item xs={12} justifyContent="center">
                <Typography className="titleDest text-center"><Skeleton variant="text" width={170} height={30} /></Typography>
                <Typography className="fieldValueDest">
                    <Skeleton variant="text" width={220} height={60} />
                </Typography>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography className="title"><Skeleton variant="text" width={50} height={25} /></Typography>
                    <Typography className="fieldValue">
                        <Skeleton variant="text" height={20} />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </div>
);

export default SkeletonSimulationCard;
