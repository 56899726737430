import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState} from 'react';
import {AuthService} from "../services/AuthService";
import useLocalStorage from "../hooks/useLocalStorage";
import {useStyle} from "./StyleProvider";

interface ContextProps {
    userSimulation: any,
    setUserSimulation: Dispatch<SetStateAction<any>>,
    login: (data: { cpf: string, birthday: string }) => Promise<any>,
    isLoading: boolean,
    error: string
}

interface AuthProviderProps {
    children: ReactNode,
}

export const AuthContext = React.createContext<ContextProps>({} as ContextProps);

export const AuthProvider = ({children}: AuthProviderProps) => {
    const [userSimulation, setUserSimulation] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const service = new AuthService();

    useEffect(() => {
        const userSimulationStorage = localStorage.getItem('userSimulation');
        !!userSimulationStorage && setUserSimulation(!!userSimulationStorage ? JSON.parse(userSimulationStorage) : null);
    }, []);

    useEffect(() => {
        if (userSimulation) {
            localStorage.setItem('userSimulation', JSON.stringify(userSimulation));
        }
    }, [userSimulation]);

    const login = async (data: { cpf: string, birthday: string }) => {
        try {
            setIsLoading(true);
            const [_response, _error, _code] = await service.validate({...data, guid: userSimulation.guid});
            if (!!_error) {
                setIsLoading(false);
                return setError(_error);
            }

            setUserSimulation({..._response.data, cpf: data.cpf, birthday: data.birthday});
            localStorage.setItem('@clientProposal:auth', JSON.stringify(_response?.data?.access_token));
            setError('');
            setIsLoading(false);
        } catch (err) {
            return err;
        }
    }

    const deleteCookie = (name: string) => {
        document.cookie = `${name}=; Path=/;  Domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    return (
        <AuthContext.Provider value={{
            userSimulation,
            setUserSimulation,
            login,
            isLoading,
            error
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
