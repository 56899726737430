import { FC, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import AppButton from '../../../../components/AppButton/AppButton';
import { useBB } from '../../../../providers/BBProvider';
import './OfferBB.scss';

interface OfferBBProps { }

const OfferBB: FC<OfferBBProps> = () => {
  const {link, getUrl} = useBB();

  useEffect(() => {
      getUrl();
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className="OfferBB"
      data-testid="OfferBB"
    >
      <Grid item xs={12}>
        <Typography variant="h3">Somos um correspondente autorizado do Banco do Brasil</Typography>
        <img src={`${process.env.PUBLIC_URL}/assets/images/products/bb/bbmais.png`} />
      </Grid>
      <Grid item xs={12} alignSelf="flex-end">
        <a href={link}>
          <AppButton
            title={'<b>Clique aqui</b> e realize o login no Banco do Brasil para apresentarmos as melhores ofertas para você'}
            className="btn-green"
            disabled={!link}
          />
        </a>
      </Grid>
    </Grid>
  );
}

export default OfferBB;
