import {faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Backdrop, Button, Fade, Grid, Modal} from '@mui/material';
import {Box} from '@mui/system';
import React, {CSSProperties, FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {useAuth} from '../../../../providers/AuthProvider';
import {useBB} from '../../../../providers/BBProvider';
import './ModalProofBB.scss';

interface ModalProofBBProps {
    show: boolean;
    onClose?: any;
    contratoPrevia: any;
    isProposals?: boolean;
}

const style: CSSProperties = {
    position: "absolute",
    width: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fdffb6",
};

const ModalProofBB: FC<ModalProofBBProps> = (
    {
        show,
        onClose,
        contratoPrevia,
        isProposals = false
    }
) => {
    const [modal, setModal] = useState<boolean>(false);
    const [scrollDown, setScrollDown] = useState<boolean>(true);
    const [showButton, setShowButton] = useState<boolean>(false);
    const {contratar, erroCod, erro, isLoading, comprovanteContratacao, setErroCod} = useBB();
    const {userSimulation} = useAuth();
    const listInnerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const toggle = () => setModal(!modal);
    const handleOnClose = () => {
        onClose(false);
    };

    const renderList = () => {
        if (isProposals) {
            return contratoPrevia?.listaTextoComprovante?.map((linha: string, key: number) => {
                return (
                    <li key={key}>{linha}</li>
                );
            });
        } else {
            return contratoPrevia?.listaTextoPrevia?.map((linha: string, key: number) => {
                return (
                    <li key={key}>{linha}</li>
                );
            });
        }
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;

            // setShowButton(true);

            if (scrollTop + clientHeight === scrollHeight) {
                setScrollDown(true);
                setShowButton(false);
            }
        }
    };

    const handleClick = () => {
        if (listInnerRef.current) {
            listInnerRef.current?.scroll({
                top: document.getElementById('list')?.offsetHeight || 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    const handleFinish = async () => {
        await contratar(userSimulation?.previewIdBB || 0);
    }

    useEffect(() => {
        if (comprovanteContratacao?.length > 0 && !isProposals) {
            navigate('/products/bb/conclusion');
        }
    }, [comprovanteContratacao]);

    useEffect(() => {
        if (!!erroCod) {
            if (erroCod === 403) {
                Swal.fire({
                    title: 'Atenção!',
                    html: 'Sua sessão expirou.<br>É necessário realizar o processo do início.',
                    icon: 'info',
                    showConfirmButton: true,
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate('/produtos/bb/pre-autenticacao');
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Atenção!',
                    html: `Houve um erro ao finalizar a proposta.<br>${erro}`,
                    showConfirmButton: true,
                    confirmButtonText: 'OK'
                }).then();
            }
            setErroCod(undefined);
            onClose(false);
        }
    }, [erroCod]);

    useEffect(() => {
        if (show) {
            // setScrollDown(false);
            // setShowButton(true);
            setModal(show);
        }
    }, [show]);

    return (
        <Modal
            open={show}
            onClose={handleOnClose}
            className="ModalProofBB"
            data-testid="ModalProofBB"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <>
                {showButton && (
                    <div className="action">
                        <Button
                            type="button"
                            className='AppButton2'
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon icon={faArrowDown}/>
                        </Button>
                    </div>
                )}

                <Fade in={show}>
                    <Box sx={style} onScroll={onScroll} ref={listInnerRef}>
                        <Grid container justifyContent="center">
                            <ul id="list">
                                {renderList()}
                            </ul>

                            {!isProposals ? (
                                <Grid item xs={12} className="text-center" mt={2}>
                                    <Button
                                        type="button"
                                        className='AppButton'
                                        onClick={handleFinish}
                                        disabled={!scrollDown || isLoading}
                                    >
                                        {!scrollDown ? (
                                            'ROLE ATÉ O FINAL PARA CONTINUAR'
                                        ) : (
                                            <>{isLoading ? 'FINALIZANDO PROPOSTA...' : 'LI, ESTOU DE ACORDO E QUERO CONTRATAR'}</>
                                        )}
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={12} className="text-center" mt={2}>
                                    <Button
                                        type="button"
                                        className='AppButton'
                                        onClick={() => setModal(false)}
                                    >
                                        FECHAR
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Fade>
            </>
        </Modal>
    );
}

export default ModalProofBB;
