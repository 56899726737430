import React, { FC, useEffect } from 'react';
import './AuthenticationBB.scss';
import { useBB } from "../../../../providers/BBProvider";
import { Alert, Grid } from "@mui/material";
import Loading from "../../../../components/Loading/Loading";
import AppButton from "../../../../components/AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import MasterPage from "../../../../components/MasterPage/MasterPage";

interface AuthenticationBbProps {
}

const AuthenticationBb: FC<AuthenticationBbProps> = () => {
    const {
        isLoading,
        setIsloading,
        bbAuth,
        erro,
        setErro,
        tokenBB,
    } = useBB();
    const navigate = useNavigate();

    const handleToken = async () => {
        const urlParams = new URLSearchParams(window.location.search);

        setIsloading(true);

        if (urlParams.get('code') && urlParams.get('state')) {
            await tokenBB({ code: urlParams.get('code'), state: urlParams.get('state') });
        } else {
            setIsloading(false);
            setErro('Você não tem permissão a essa tela');
        }
    };

    useEffect(() => {
        if (bbAuth && Object.keys(bbAuth).length > 0) {
            handleToken().then();
        }
    }, [bbAuth]);

    return (
        <MasterPage>
            <Grid
                container
                className="AuthenticationBB"
                data-testid="AuthenticationBb"
            >
                {erro ? (
                    <>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            alignItems="center"
                            justifyContent={'center'}
                            flexDirection="row"
                            paddingTop='100px'
                        >

                            <Alert severity='error'>{erro}</Alert>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            alignItems="center"
                            justifyContent={'center'}
                            flex={1}
                            flexDirection="row"
                            marginY="100px"
                        >
                            <AppButton
                                title='Voltar'
                                className='btn-inverse'
                                onClick={() => navigate('/')}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        {isLoading && <Loading message={'Aguarde, você será redirecionado...'} />}
                    </>
                )}
            </Grid>
        </MasterPage>
    )
};

export default AuthenticationBb;
