import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import './InputCurrencyDecimal.scss';

interface HTMLNumericElement extends Omit<HTMLInputElement, 'value'> {
    value: number | null;
}

type NumericInputProps = import('@mui/material').TextFieldProps & {
    value?: number | string;
    onChange(e: React.ChangeEvent<HTMLNumericElement>): void;

    precision: number;
    thousandChar: string;
    decimalChar: string;
};

const InputCurrencyDecimal = (props: NumericInputProps) => {
    const { value, precision, thousandChar, decimalChar, ...inputProps } = props;

    const formatter = useMemo(
        () =>
            new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision
            }),

        [thousandChar, decimalChar]
    );

    const isNumber = (string: string) => {
        return !isNaN(Number(string));
    }

    if (!decimalChar) {
        throw new Error('Decimal char should not be an empty string!');
    }

    if (!thousandChar) {
        throw new Error('Thousand char should not be an empty string!');
    }

    const format = (number: number) => {
        const result = formatter
            .format(number)
            .replace(',', decimalChar)
            .replaceAll('.', thousandChar);

        return result;
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        (e.key === ' ') && e.preventDefault();

        if (e.ctrlKey || e.shiftKey || e.key === 'Backspace' || e.key === 'Enter') {
            return;
        }

        (!isNumber(e.key)) && e.preventDefault();
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newEvent: React.ChangeEvent<HTMLNumericElement> = {
            ...e,
            currentTarget: {
                ...e.currentTarget,
                value: 0
            },
            target: {
                ...e.target,
                value: 0
            }
        };

        let numericRepresentation = e.target.value;
        numericRepresentation = numericRepresentation.replaceAll(thousandChar, '');
        numericRepresentation = numericRepresentation.replace(decimalChar, '');

        if (numericRepresentation === '') {
            e.target.value = '';
            newEvent.target.value = null;
            newEvent.currentTarget.value = null;
            return props.onChange && props.onChange(newEvent);
        }

        if (isNumber(numericRepresentation)) {
            const withPrecision = +numericRepresentation / 10 ** precision;
            const formattedNumber = format(withPrecision);

            newEvent.target.value = withPrecision;
            newEvent.currentTarget.value = withPrecision;

            e.target.value = formattedNumber;

            props.onChange && props.onChange(newEvent);
        }
    }

    return (
        <>
            <TextField
                {...inputProps}
                defaultValue={parseFloat(inputProps?.defaultValue as string)?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) || ''}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                className="InputCurrencyDecimal"
                data-testid="InputCurrencyDecimal"
            />
        </>
    );
}

export default InputCurrencyDecimal;