import React, {FC} from 'react';
import './MasterPage.scss';
import {Grid} from "@mui/material";
import {useStyle} from "../../providers/StyleProvider";

interface MasterPageProps extends React.HTMLAttributes<HTMLDivElement> {
}

const MasterPage: FC<MasterPageProps> = ({children}) => {
    const {primaryColor, logo} = useStyle();

    return (
        <Grid
            className="MasterPage"
            data-testid="MasterPage"
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            bgcolor={primaryColor}
            paddingTop='30px'
        >
            <Grid item xs={12} alignSelf="flex-start" marginTop={'8rem'}>
                {!!logo && <img src={logo} className="app-logo" alt="Logo"/>}
            </Grid>

            {children}
        </Grid>
    )
};

export default MasterPage;
