export class ResponseSimulationBB {
    id?: number;
    expiresDate?: string;
    limiteCredito?: number;
    parcelas?: InstallmentSimulationBB[] = [];
}

export class InstallmentSimulationBB {
    numero?: number;
    valor?: number;
    totalEmprestimo?: number;
    imposto?: number;
    taxaMensal?: number;
    taxaAnual?: number;
    taxaCETMensal?: number;
    taxaCETAnual?: number;
}