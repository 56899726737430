import moment from "moment";
import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BBService} from "../services/products/BB/BBService";
import {LineCreditBB} from "../models/products/BB/LineCreditBB";
import {RequestSimulacaoBB} from "../models/products/BB/RequestSimulacaoBB";
import {ResponseSimulationBB} from "../models/products/BB/ResponseSimulationBB";
import {useAuth} from "./AuthProvider";
import {useStyle} from "./StyleProvider";

interface ContextProps {
    produtos: LineCreditBB[];
    setProdutos: Dispatch<SetStateAction<LineCreditBB[]>>;
    contratoPrevia: any;
    setContratoPrevia: Dispatch<SetStateAction<any>>;
    bbAuth: any;
    setbbAuth: Dispatch<SetStateAction<any>>;
    link: string;
    setLink: Dispatch<SetStateAction<string>>;
    authId: number;
    timer: number | null;
    setTimer: Dispatch<SetStateAction<number | null>>;
    isLoading: boolean;
    setIsloading: Dispatch<SetStateAction<boolean>>;
    isLoadingSimulation: boolean;
    setIsLoadingSimulation: Dispatch<SetStateAction<boolean>>;
    erro: string;
    setErro: Dispatch<SetStateAction<string>>;
    getUrl: () => Promise<any>;
    contratar: (previewId: number) => Promise<any>;
    tokenBB: (params: any) => Promise<any>;
    getProducts: () => Promise<any>;
    getPreview: () => Promise<any>;
    simulacao: ResponseSimulationBB | null;
    setSimulacao: Dispatch<SetStateAction<ResponseSimulationBB | null>>;
    getSimulacao: (payload: RequestSimulacaoBB) => Promise<any>;
    comprovanteContratacao: string[];
    erroCod: number | undefined;
    setErroCod: Dispatch<SetStateAction<number | undefined>>;
    erroSimulacao: string | undefined;
    erroCodSimulacao: number | undefined;
}

export const BBContext = createContext<ContextProps>({} as ContextProps);

interface BBProviderProps {
    children: ReactNode
}

export const BBProvider = ({children}: BBProviderProps) => {
    const [produtos, setProdutos] = useState<LineCreditBB[]>([]);
    const [contratoPrevia, setContratoPrevia] = useState<any>({});
    const [bbAuth, setbbAuth] = useState<any>({});
    const [link, setLink] = useState<string>('');
    const [authId, setAuthId] = useState<number>(0);
    const [preId, setPreId] = useState<number>(0);
    const [timer, setTimer] = useState<number | null>(null);
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isLoadingSimulation, setIsLoadingSimulation] = useState<boolean>(false);
    const [erro, setErro] = useState<string>('');
    const [erroCod, setErroCod] = useState<number>();
    const [erroSimulacao, setErroSimulacao] = useState<string>('');
    const [erroCodSimulacao, setErroCodSimulacao] = useState<number>();
    const [comprovanteContratacao, setComprovanteContratacao] = useState<string[]>([]);

    const [simulacao, setSimulacao] = useState<ResponseSimulationBB | null>(null);
    const {userSimulation, setUserSimulation} = useAuth();
    const service = new BBService();
    const navigate = useNavigate();

    const getUrl = async () => {
        setIsloading(true);

        const [response, error, code] = await service.getUrl({
            guid: userSimulation?.guid,
            cpf: userSimulation?.cpf,
            birthday: userSimulation?.birthday
        });

        if (code !== 200) {
            setErro(error);
            setErroCod(code);
            return;
        } else {
            setErro('');
            setErroCod(undefined);
        }
        setIsloading(false);

        setLink(response?.data?.url);

        localStorage.setItem('bbAuth', JSON.stringify(response?.data));

        window.location.href = response?.data?.url;
    }

    const tokenBB = async (params: any) => {
        setIsloading(true);

        const [response, error, code] = await service.tokenBB({...params, authId});

        if (code !== 200) {
            setErro(error);
            setErroCod(code);
        } else {
            setErro('');
            setErroCod(undefined);
        }
        setIsloading(false);

        const bb = {...bbAuth, ...params};

        if (response?.data?.expiresDate) {
            localStorage.setItem('bbAuth', JSON.stringify({...bb, ...response?.data}));

            setbbAuth({...bb, ...response?.data});

            navigate('/products/bb/available');
        }
    }

    const getProducts = async () => {
        setIsloading(true);

        const [response, error, code] = await service.getProdutos(authId);

        if (code !== 200) {
            setErro(error);
            setErroCod(code);
        } else {
            setErro('');
            setErroCod(undefined);
        }
        setIsloading(false);

        setProdutos(response?.data?.linhasCredito);
        setPreId(response?.data?.id);

        // setMissingTime(response?.data?.serverDate);
    }

    const getPreview = async () => {
        setIsloading(true);

        const [response, error, code] = await service.contratoPreview({
            simulacaoId: userSimulation?.simulacaoId,
            parcelas: userSimulation?.parcelas
        });

        if (code !== 200) {
            setErro(error);
            setErroCod(code);
        } else {
            setErro('');
            setErroCod(undefined);
            setContratoPrevia(response?.data);
            setMissingTime(response?.data?.serverDate);
            setUserSimulation({...userSimulation, previewIdBB: response?.data?.id});
        }
        setIsloading(false);
    }

    const setMissingTime = (serverDate: string) => {
        const bb = {...bbAuth, ...{serverDate}};
        localStorage.setItem('bbAuth', JSON.stringify(bb));

        getMissingTime(serverDate, bbAuth?.expiresDate);
    }

    const getSimulacao = async (payload: RequestSimulacaoBB) => {
        setIsLoadingSimulation(true);
        setSimulacao(null);

        const [response, error, code] = await service.getSimulacao({...payload, id: preId});

        if (code !== 200) {
            setErroSimulacao(error);
            setErroCodSimulacao(code);
        } else {
            setErroSimulacao('');
            setErroCodSimulacao(undefined);
            setSimulacao(response?.data);
            setMissingTime(response?.data?.serverDate);
        }

        setIsLoadingSimulation(false);
    }

    const getMissingTime = (serverDate: string, expiresDate: string) => {
        const dateInicial = moment(new Date(serverDate));
        const dataFinal = moment(new Date(expiresDate));

        // foi decrementado 10 minutos do horário do servidor, em razão do atraso de 3 minutos em relação ao horário atual
        let duration = moment.duration(dataFinal.diff(dateInicial));
        let seconds = parseInt(duration.asSeconds().toString());

        setTimer(seconds > 0 ? seconds : 0);
    }

    const contratar = async (previewId: number) => {
        setIsloading(true);

        const [response, error, code] = await service.contratar(
            previewId,
            userSimulation?.indicadorUtilizacaoChequeEspecialBB || false,
            userSimulation?.indicadorDebitoParcelaAtrasoBB || false);

        if (code !== 200 && code !== 201) {
            setErro(error);
            setErroCod(code);
        } else {
            setErro('');
            setErroCod(undefined);
            setUserSimulation({...userSimulation, urlFormalizacao: response?.data?.urlFormalizacao})
            setComprovanteContratacao(response?.data?.listaTextoComprovante);
        }

        setIsloading(false);
    }

    useEffect(() => {
        setSimulacao(null);

        const bbAuthStorage = localStorage.getItem('bbAuth');

        if (bbAuthStorage !== 'undefined') {
            setbbAuth(!!bbAuthStorage ? JSON.parse(bbAuthStorage) : null);
        }

    }, []);

    useEffect(() => {
        if (bbAuth && Object.keys(bbAuth).length > 0) {
            getMissingTime(bbAuth?.serverDate, bbAuth?.expiresDate);
            setAuthId(bbAuth.authId);

            // colocar um aviso de redirecionamento

            localStorage.setItem('bbAuth', JSON.stringify(bbAuth));
        }
    }, [bbAuth]);

    return (
        <BBContext.Provider value={{
            bbAuth,
            setbbAuth,
            link,
            setLink,
            authId,
            isLoading,
            setIsloading,
            isLoadingSimulation,
            setIsLoadingSimulation,
            erro,
            setErro,
            erroSimulacao,
            erroCodSimulacao,
            getUrl,
            tokenBB,
            produtos,
            setProdutos,
            getProducts,
            timer,
            setTimer,
            simulacao,
            setSimulacao,
            getSimulacao,
            erroCod,
            setErroCod,
            getPreview,
            contratoPrevia,
            setContratoPrevia,
            contratar,
            comprovanteContratacao
        }}>
            {children}
        </BBContext.Provider>
    )
}

export const useBB = () => useContext(BBContext);