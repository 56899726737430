export function FormatStringCurrencyToNumber(value: any): number {
    if (typeof (value) === 'number') {
        return value;
    }
    
    if (isNaN(Number(value.toString().replace('R$', '').replace('.', '').replace(',', '.')))) {
        return 0;
    } else {
        return Number(value.toString().replace('R$', '').replace('.', '').replace(',', '.'))
    }
}