import {CSSProperties, FC, useState} from 'react';
import {Backdrop, Checkbox, Fade, FormControlLabel, FormGroup, Grid, Modal, Typography} from '@mui/material';
import {Box} from '@mui/system';
import './ModalAcceptDebitBB.scss';
import {useAuth} from '../../../../providers/AuthProvider';
import AppButton from '../../../AppButton/AppButton';
import {Check, CheckCircle, Circle, CircleOutlined} from '@mui/icons-material';

interface ModalAcceptDebitBBProps {
    show: boolean;
    onClose?: any;
    consigned: boolean;
    setShowProof: any;
}

const style: CSSProperties = {
    position: "absolute",
    width: "auto%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: 4,
};

const ModalAcceptDebitBB: FC<ModalAcceptDebitBBProps> = ({show, onClose, consigned, setShowProof}) => {
    const [limitCheck, setLimitCheck] = useState<boolean>(false);
    const [_accountDebit, _setAccountDebit] = useState<boolean>(false);
    const [accountDebit, setAccountDebit] = useState<boolean>(false);
    const [_limitCheck, _setLimitCheck] = useState<boolean>(false);
    const [proceed, setProceed] = useState<boolean>(false);
    const {userSimulation, setUserSimulation} = useAuth();

    const handleOnClose = () => {
        setUserSimulation({
            ...userSimulation,
            indicadorDebitoParcelaAtrasoBB: accountDebit ? accountDebit : _accountDebit,
            indicadorUtilizacaoChequeEspecialBB: limitCheck ? limitCheck : _limitCheck
        });
        setShowProof(true);
        onClose(false);
    };

    const handleProceeding = () => {
        setProceed(true);
        if (limitCheck && accountDebit) {
            handleOnClose();
        }
    }

    return (
        <Modal
            open={show}
            onClose={handleOnClose}
            className="ModalAcceptDebitBB"
            data-testid="ModalAcceptDebitBB"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={show}>
                <Box sx={style}>
                    <Grid container className="text-justify">
                        {!proceed && (
                            <>
                                <Typography variant="h4">
                                    {consigned ? (
                                        `Na impossibilidade parcial ou total de consignação da parcela devida na folha de pagamento,
                    independentemente do motivo, autorizo o Banco do Brasil debitar na(s) conta(s) indicada,
                    os valores referentes a liquidação, prestações, encargos financeiros e acessórios quando dos
                    respectivos vencimentos/exigibilidade do empréstimo/financeiro acima indicado, que me foi
                    concedido.`
                                    ) : (
                                        `Autorizo o Banco do Brasil debitar na(s) conta(s) indicada, os valores referentes a liquidação, 
                    prestações, encargos financeiros e acessórios quando dos respectivos vencimentos/exigibilidade do 
                    empréstimo/financiamento acima indicado, que me foi concedido.`
                                    )}
                                </Typography>

                                <Typography variant="h3">
                                    <b>Autorizo ainda a:</b>
                                </Typography>

                                <Grid item xs={12} flexDirection="column" className="round">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={
                                                        <CircleOutlined/>
                                                    }
                                                    checkedIcon={<CheckCircle/>}
                                                    checked={limitCheck}
                                                    onChange={() => {
                                                        setLimitCheck((current: boolean) => !current);
                                                    }}
                                                />
                                            }
                                            label="Utilização do limite do cheque especial, quando não houver saldo disponível para pagar o débito."
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} flexDirection="column" className="round">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={
                                                        <CircleOutlined/>
                                                    }
                                                    checkedIcon={<CheckCircle/>}
                                                    checked={accountDebit}
                                                    onChange={() => {
                                                        setAccountDebit((current: boolean) => !current);
                                                    }}
                                                />
                                            }
                                            label="Debitar obrigações vencidas, inclusive por meio de  lançamentos  parciais, na conta indicada no contrato."
                                        />
                                    </FormGroup>
                                </Grid>

                                <Typography variant="h5">
                                    Essa autorização de débito será válida até a liquidação total da operação.
                                </Typography>
                            </>
                        )}

                        {proceed && (!limitCheck || !accountDebit) && (
                            <>
                                {!limitCheck && (
                                    <Grid item xs={12} flexDirection="column" className="round">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <CircleOutlined/>
                                                        }
                                                        checkedIcon={<CheckCircle/>}
                                                        checked={_limitCheck}
                                                        onChange={() => {
                                                            _setLimitCheck((current: boolean) => !current);
                                                        }}
                                                    />
                                                }
                                                label="
                          Utilizar o limite de seu cheque especial poderá ajudar a manter sua operação em
                          dia caso o saldo disponível em conta não seja suficiente para o pagamento da(s) parcela(s).
                          Autoriza o débito com uso do limite do cheque especial?.
                        "
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}

                                {!accountDebit && (
                                    <Grid item xs={12} flexDirection="column" className="round">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <CircleOutlined/>
                                                        }
                                                        checkedIcon={<CheckCircle/>}
                                                        checked={_accountDebit}
                                                        onChange={() => {
                                                            _setAccountDebit((current: boolean) => !current);
                                                        }}
                                                    />
                                                }
                                                label="
                          Caso a parcela não seja recebida até a data do vencimento, a operação será considerada VENCIDA,
                          haverá a incidência de encargos de INADIMPLÊNCIA conforme previsto no contrato e poderá gerar
                          anotações restritivas junto aos órgãos de proteção ao crédito. 
                          Autoriza o débito em datas posteriores ao vencimento?
                        "
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}
                            </>
                        )}

                        <AppButton
                            title="CONTINUAR"
                            onClick={() => !proceed ? handleProceeding() : handleOnClose()}
                            textColor={'var(--white)'}
                        />
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ModalAcceptDebitBB;
