import {ThemeProvider} from '@mui/material/styles';
import {Route, Routes} from 'react-router-dom';
import {createTheme} from '@mui/material/styles';
import Login from '../pages/Login/Login';
import {StyleProvider} from '../providers/StyleProvider';
import {AuthenticationGate} from './AuthenticationGate';
import OfferBB from '../pages/Products/BB/OfertaBB/OfferBB';
import {AuthProvider} from '../providers/AuthProvider';
import {BBProvider} from '../providers/BBProvider';
import AvailableBB from '../pages/Products/BB/AvailableBB/AvailableBB';
import PreviewBB from '../pages/Products/BB/PreviewBB/PreviewBB';
import ConclusionBB from '../pages/Products/BB/ConclusionBB/ConclusionBB';
import Splash from "../pages/Splash/Splash";
import AuthenticationBB from "../pages/Products/BB/AuthenticationBB/AuthenticationBB";

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        subtitle1: {
            fontSize: 16,
            lineHeight: '22px',
        },
        body1: {
            fontWeight: 500,
        },
        button: {
            fontStyle: 'italic',
        },
    },
    components: {
        // Inputs
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    color: "var(--gray-dark)",

                    "&.MuiFilledInput-underline": {
                        backgroundColor: "#fff",
                    }
                }
            }
        }
    }
});

const RootRoute = () => {
    return (
        <ThemeProvider theme={theme}>
            <StyleProvider>
                <AuthProvider>
                    <BBProvider>
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/:guid" element={<Splash/>}/>

                            <Route element={<AuthenticationGate/>}>
                                <Route path="products">
                                    <Route path="bb">
                                        <Route path="offer" element={<OfferBB/>}/>
                                        <Route path="available" element={<AvailableBB/>}/>
                                        <Route path="preview" element={<PreviewBB/>}/>
                                        <Route path="conclusion" element={<ConclusionBB/>}/>
                                        <Route path="authentication" element={<AuthenticationBB/>}/>
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </BBProvider>
                </AuthProvider>
            </StyleProvider>
        </ThemeProvider>
    );
}

export default RootRoute;