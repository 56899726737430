import { validateCPF } from "validations-br";
import * as yup from "yup";


export const schema = yup.object().shape({
  cpf: yup
    .string()
    .required()
    .max(14)
    .test(
      "invalid",
      "CPF é inválido",
      (value: string | undefined) => validateCPF(value || '')
    ),
  birthday: yup
    .string()
    .required(),
});