import {CSSProperties, FC, useEffect, useRef, useState} from "react";
import {Backdrop, Box, Fade, Grid, Modal, Typography} from "@mui/material";
import FormatMoney from "../../../../utils/FormatMoney";
import FormatDate from "../../../../utils/FormatDate";
import {InstallmentSimulationBB} from "../../../../models/products/BB/ResponseSimulationBB";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../providers/AuthProvider";
import {useBB} from "../../../../providers/BBProvider";
import {FormatStringCurrencyToNumber} from "../../../../utils/FormatStringCurrencyToNumber";
import SkeletonSimulationCard from "../SkeletonSimulationCard/SkeletonSimulationCard";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import "./ModalSimulateBB.scss";
import AppButton from "../../../AppButton/AppButton";

interface ModalSimulateBBProps {
    show: boolean;
    onClose?: any;
}

const style: CSSProperties = {
    position: "absolute",
    width: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: 4,
};

const ModalSimulateBB: FC<ModalSimulateBBProps> = ({show, onClose}) => {
    const [installmentsPossible, setInstallmentsPossible] = useState<InstallmentSimulationBB[]>([]);
    const [currentInstallment, setCurrentInstallment] = useState<InstallmentSimulationBB>(new InstallmentSimulationBB());
    const [changeInstallment, setChangeInstallment] = useState<boolean>(false);
    const [valueSimulate, setValueSimulate] = useState<number>(0);
    const [value, setValue] = useState<number>(0);
    const [valueOk, setValueOk] = useState<boolean>(true);
    const [indexInstallment, setIndexInstallment] = useState<number>(0);
    const [installments, setInstallments] = useState<number>(0)
    const navigate = useNavigate();
    const {userSimulation, setUserSimulation} = useAuth();
    const {
        getSimulacao,
        simulacao,
        erroSimulacao,
        erroCodSimulacao,
        isLoadingSimulation,
    } = useBB();
    const isInitialMount = useRef(true);

    const handleOnClose = () => {
        setValue(0);
        setValueSimulate(0);
        onClose(false)
    };

    useEffect(() => {
        if (show) {
            setValue(userSimulation?.productBB?.valorMaxEmprestimo || '0');
            simular();
        }
    }, [show]);

    useEffect(() => {
        if (erroCodSimulacao === 403) {
            // Swal.fire({
            //     title: "Atenção!",
            //     html: "Sua sessão expirou.<br>É necessário realizar o processo do início.",
            //     icon: "info",
            //     showConfirmButton: true,
            //     confirmButtonText: "OK"
            // }).then(() => {
            //     navigate("/produtos/bb/pre-autenticacao");
            // });
        }
    }, [erroCodSimulacao]);

    useEffect(() => {
        if (installments !== userSimulation?.productBB?.qtdeMaxParcela) {
            setChangeInstallment(true);
            setTimeout(() => {
                setChangeInstallment(false);
            }, 400);
        }
    }, [currentInstallment]);

    useEffect(() => {
        if (simulacao?.parcelas && !!setInstallmentsPossible) {
            simulacao.parcelas.sort((a, b) => (a.numero || 0) > (b.numero || 0) ? 1 : -1);
            setInstallmentsPossible(simulacao.parcelas || []);
            setInstallments(userSimulation?.productBB?.qtdeMaxParcela || 0);
        }
    }, [simulacao]);

    useEffect(() => {
        if (value && userSimulation?.productBB) {
            if (userSimulation.productBB?.valorMaxEmprestimo && (FormatStringCurrencyToNumber(value) > userSimulation.productBB?.valorMaxEmprestimo ||
                FormatStringCurrencyToNumber(value) < (userSimulation.productBB.valorMinEmprestimo || 0))) {
                setValueOk(false);
            } else {
                setValueOk(true);
            }
        }
    }, [value]);

    useEffect(() => {
        if (installmentsPossible?.length > 0) {
            setIndexInstallment(installmentsPossible.length - 1);
            setCurrentInstallment && setCurrentInstallment(installmentsPossible[installmentsPossible.length - 1]);
            setInstallments(installmentsPossible[installmentsPossible.length - 1].numero || 0);
        }
    }, [installmentsPossible]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (userSimulation?.productBB) {
                (userSimulation?.simulacaoId || 0) > 0 && (userSimulation?.parcelas || 0) > 0 && navigate(`/products/bb/preview`);
            }
        }
    }, [userSimulation]);

    const updateInstallment = (action: string) => {
        if (action === "+") {
            if (indexInstallment < installmentsPossible.length - 1) {
                setIndexInstallment((current) => {
                    setInstallments && setInstallments(installmentsPossible[current + 1].numero || 0);
                    setCurrentInstallment && setCurrentInstallment(installmentsPossible[current + 1]);
                    return current + 1;
                });
            }
        } else if (action === "-") {
            if (indexInstallment >= 1) {
                setIndexInstallment((current) => {
                    setInstallments && setInstallments(installmentsPossible[current - 1].numero || 0);
                    setCurrentInstallment && setCurrentInstallment(installmentsPossible[current - 1]);
                    return current - 1;
                });
            }
        }
    }

    const simular = async () => {
        setValueSimulate(FormatStringCurrencyToNumber(value) || userSimulation?.productBB?.valorMaxEmprestimo || 0);
        await getSimulacao({
            valor: FormatStringCurrencyToNumber(value) || userSimulation?.productBB?.valorMaxEmprestimo,
            beneficioId: userSimulation?.productBB?.beneficioId,
            convenioId: userSimulation?.productBB?.convenioId,
            linhaCreditoId: userSimulation?.productBB?.linhaCreditoId,
            vencimento: userSimulation?.productBB?.dataMinInicioParcela
        });
    }

    const handleProposal = () => {
        setUserSimulation({...userSimulation, simulacaoId: simulacao?.id, parcelas: currentInstallment?.numero});
    }

    return (
        <Modal
            open={show}
            onClose={handleOnClose}
            className="ModalSimulateBB"
            data-testid="ModalSimulateBB"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={show}>
                <Box sx={style}>
                    {!isLoadingSimulation && !changeInstallment && (
                        <>
                            {erroSimulacao === '' ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={12} className="header">
                                            <Typography variant="h4">
                                                {userSimulation?.productBB?.convenio || userSimulation?.productBB?.linhaCredito}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className="titleDest">Valor simulado</Typography>
                                            <Typography className="fieldValueDest">
                                                {FormatMoney(valueSimulate)}
                                            </Typography>
                                        </Grid>

                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography className="title">Primeiro<br/>Vcto.</Typography>
                                                <Typography className="fieldValue">
                                                    {FormatDate(userSimulation?.productBB?.dataMinInicioParcela)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">Valor<br/>Parcela</Typography>
                                                <Typography className="fieldValue">
                                                    {FormatMoney(currentInstallment.valor)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">Qtd.<br/>Parcelas</Typography>
                                                <Typography className="fieldValue">
                                                    {currentInstallment.numero}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">IOF</Typography>
                                                <Typography className="fieldValue">
                                                    {FormatMoney(currentInstallment?.imposto)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">Taxa Mensal</Typography>
                                                <Typography className="fieldValue">
                                                    {`${currentInstallment?.taxaMensal?.toFixed(2)}%`}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">CET Mensal</Typography>
                                                <Typography className="fieldValue">
                                                    {`${currentInstallment?.taxaCETMensal?.toFixed(2)}%`}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">Taxa<br/>Anual</Typography>
                                                <Typography className="fieldValue">
                                                    {`${currentInstallment?.taxaAnual?.toFixed(2)}%`}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography className="title">CET<br/>Anual</Typography>
                                                <Typography className="fieldValue">
                                                    {`${currentInstallment?.taxaCETAnual?.toFixed(2)}%`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="lineTitle">
                                        <Grid item xs={12}>
                                            <Typography className="titleInput">Parcelas</Typography>
                                        </Grid>

                                        <Grid item xs={12} className="halfInstallment">
                                            <div>
                                                <button className="btnRound"
                                                        onClick={() => updateInstallment("-")}>-
                                                </button>
                                            </div>
                                            <div className="installments">
                                                {installments}
                                            </div>
                                            <div>
                                                <button className="btnRound"
                                                        onClick={() => updateInstallment("+")}>+
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="halfValue">
                                        <Grid item xs={12} className="lineTitle m-0">
                                            <Typography className="titleInput">Digite novo valor a ser
                                                simulado</Typography>
                                        </Grid>

                                        <Grid item xs={12} className="lineTitle text-center m-0">
                                            <InputCurrencyDecimal
                                                precision={2}
                                                decimalChar=','
                                                thousandChar='.'
                                                defaultValue={value}
                                                value={value}
                                                onChange={(e: any) => setValue(Number(e.target.value) || 0)}
                                                variant='filled'
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="body2">Valor
                                                máximo <b>{FormatMoney(userSimulation?.productBB?.valorMaxEmprestimo)}</b></Typography>
                                        </Grid>

                                        {!valueOk && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" className="error">
                                                    Valor mínimo
                                                    é {FormatMoney(userSimulation?.productBB?.valorMinEmprestimo)}<br/>e
                                                    o
                                                    máximo
                                                    é {FormatMoney(userSimulation?.productBB?.valorMaxEmprestimo || 0)}
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid container mt={2}>
                                            <Grid item xs={12} md={6} className="text-center">
                                                <AppButton
                                                    title={"SIMULAR NOVO VALOR"}
                                                    disabled={!valueOk}
                                                    onClick={() => {
                                                        simular()
                                                    }}
                                                    className="blueBB"
                                                    textColor={'white'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} className="text-center">
                                                <AppButton
                                                    title={"CONTRATAR"}
                                                    disabled={isLoadingSimulation}
                                                    onClick={() => {
                                                        handleProposal()
                                                    }}
                                                    textColor={'var(--blue-bb)'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className="titleDest">Ocorreu um erro ao simular</Typography>
                                        <Typography className="fieldValueDest">
                                            {erroSimulacao}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                    {(isLoadingSimulation || changeInstallment) && (
                        <>
                            <SkeletonSimulationCard/>
                        </>
                    )}
                </Box>
            </Fade>
        </Modal>
    );
}

export default ModalSimulateBB;
