import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

interface ContextProps {
    primaryColor: string,
    secondaryColor: string,
    logo: string,
    loginText: string,
    handleStyle: () => Promise<any>,
    conclusionImage: string
}

export const StyleContext = React.createContext<ContextProps>({} as ContextProps);

interface StyleProviderProps {
    children: ReactNode,
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
    const [primaryColor, setPrimaryColor] = useState<string>('white');
    const [secondaryColor, setSecondaryColor] = useState<string>('white');
    const [logo, setLogo] = useState<string>(`${process.env.PUBLIC_URL}/assets/app-logo.svg`);
    const [loginText, setLoginText] = useState<string>('');
    const [conclusionImage, setConclusionImage] = useState<string>('');
    const [inputColor, setInputColor] = useState<string>('white');

    useEffect(() => {
        handleStyle().then();
    }, []);

    const handleStyle = async () => {
        if (window?.location?.hostname?.includes('bevioficial.com.br')) {
            updateStyle('white',
                '#222343',
                '#222343',
                `${process.env.PUBLIC_URL}/assets/bevi-logo.png`,
                '',
                'favicon_bevi.png',
                'Proposta Bevi',
                `${process.env.PUBLIC_URL}/assets/images/products/bb/conclusion_bevi.svg`,
                '#222343');
        } else {
            updateStyle('#E60000',
                'white',
                'white',
                `${process.env.PUBLIC_URL}/assets/app-logo.svg`,
                'Bem-vindo ao Dinheiro Sim.',
                'favicon.ico',
                'Proposta Dinheiro Sim',
                `${process.env.PUBLIC_URL}/assets/images/products/bb/conclusion.png`,
                '#E60000');
        }
    }

    const updateStyle = (primary: string,
        secondary: string,
        progress: string,
        _logo: string,
        _loginText: string,
        favicon: string,
        title: string,
        conclusionImg: string,
        _inputColor: string) => {
        const root = document.documentElement;
        setLogo(_logo);
        root.style.setProperty('--primary', primary);
        root.style.setProperty('--secondary', secondary);
        root.style.setProperty('--progress-circle', progress);
        root.style.setProperty('--input', _inputColor);
        document.getElementById('favicon')?.setAttribute('href', '');
        document.title = title;
        setPrimaryColor(primary);
        setSecondaryColor(secondary);
        setLogo(_logo);
        setLoginText(_loginText);
        setConclusionImage(conclusionImg);
        setInputColor(_inputColor);
    }

    return (
        <StyleContext.Provider value={{
            primaryColor,
            secondaryColor,
            logo,
            loginText,
            handleStyle,
            conclusionImage
        }}>
            {children}
        </StyleContext.Provider>
    );
};

export const useStyle = () => useContext(StyleContext);
