import {Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {CSSProperties, FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../../providers/AuthProvider';
import {useBB} from '../../../../providers/BBProvider';
import AppButton from '../../../../components/AppButton/AppButton';
import Loading from '../../../../components/Loading/Loading';
import TimerProgress from '../../../../components/TimerProgress/TimerProgress';
import DetailsProposalBB from '../../../../components/Products/BB/DetailsProposalBB/DetailsProposalBB';
import ModalAcceptDebitBB from '../../../../components/Products/BB/ModalAcceptDebitBB/ModalAcceptDebitBB';
import './PreviewBB.scss';
import ModalProofBB from '../../../../components/Products/BB/ModalProofBB/ModalProofBB';

interface PreviewBBProps {
}

const style: CSSProperties = {
    backgroundColor: "#fff",
    padding: 4,
};

const PreviewBB: FC<PreviewBBProps> = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalDebit, setShowModalDebit] = useState<boolean>(false);
    const {
        contratoPrevia,
        getPreview,
        isLoading,
        setIsloading,
        timer,
        bbAuth,
        erroCod,
        erro,
    } = useBB();
    const {userSimulation} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setIsloading(true);

        if (bbAuth && Object.keys(bbAuth).length > 0) {
            getPreview();
        }
    }, [bbAuth]);

    useEffect(() => {
        if (erroCod === 403) {
            // Swal.fire({
            //     title: 'Atenção!',
            //     html: 'Sua sessão expirou.<br>É necessário realizar o processo do início.',
            //     icon: 'info',
            //     showConfirmButton: true,
            //     confirmButtonText: 'OK'
            // }).then(() => {
            //     navigate('/produtos/bb/pre-autenticacao');
            // });
        }
    }, [erroCod]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="flex-start"
            className="PreviewBB"
            data-testid="PreviewBB"
            rowSpacing={1}
        >
            <Grid item xs={12}>
                <TimerProgress
                    missingTime={timer ?? 0}
                    color={"var(--yellow-bb)"}
                    backGroundColor={"var(--blue-bb)"}
                />
            </Grid>

            <Grid item xs={12} className="wrapper">
                {!erroCod ? (
                    <Grid
                        item
                        xs={12}
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3">Detalhes do seu contrato</Typography>
                        </Grid>

                        {contratoPrevia && (
                            <>
                                <Box sx={style}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className="dest"><b>Revise os dados da proposta
                                                #{contratoPrevia?.simulacaoId}</b></Typography>
                                        </Grid>

                                        <Grid item xs={12} mt={1}>
                                            <Typography className="dest" style={{color: "#4d4c4c"}}>Confira se os
                                                valores abaixo estão de acordo com os apresentados no momento da sua
                                                contratação.</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <DetailsProposalBB proposal={contratoPrevia}/>
                                        </Grid>

                                        <Grid item xs={12} mt={3}>
                                            <AppButton
                                                title={'VOLTAR'}
                                                onClick={() => navigate('/products/bb/available')}
                                                textColor={'var(--yellow-bb)'}
                                                className={'bgBlueBB'}
                                            />

                                            <AppButton
                                                title={'CONTINUAR'}
                                                onClick={() => contratoPrevia?.indicadorOpcao === 'S' ? setShowModalDebit(true) : setShowModal(true)}
                                                textColor={'var(--blue-bb)'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12} marginTop={'50%'} className="text-center">
                        <Typography variant="h4">{erro}</Typography>

                        <AppButton
                            title={"TENTAR NOVAMENTE"}
                            onClick={getPreview}
                            textColor={'var(--blue-bb)'}
                        />
                    </Grid>
                )}
            </Grid>

            {isLoading && !showModal && (
                <Loading message="Estamos buscando a sua proposta..."/>
            )}

            {showModal && (
                <ModalProofBB
                    show={showModal}
                    onClose={setShowModal}
                    contratoPrevia={contratoPrevia}
                />
            )}

            {showModalDebit && (
                <ModalAcceptDebitBB
                    show={showModalDebit}
                    onClose={setShowModalDebit}
                    consigned={true}
                    setShowProof={setShowModal}
                />
            )}
        </Grid>
    );
}

export default PreviewBB;
