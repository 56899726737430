import {BaseService} from "./BaseService";
import api from "./Api";

const URL = 'api/auth/';

export class AuthService extends BaseService {

    async validate(payload: { guid: string, cpf: string, birthday: string }) {
        try {
            return this.handleResponse(await api.post(`${URL}user/validate`, payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }


}