import {FC, useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import './ConclusionBB.scss';
import AppButton from "../../../../components/AppButton/AppButton";
import {useStyle} from "../../../../providers/StyleProvider";

interface ConclusionBBProps {
}

const ConclusionBB: FC<ConclusionBBProps> = () => {
    const navigate = useNavigate();
    const {conclusionImage} = useStyle();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            className="ConclusionBB"
            data-testid="ConclusionBB"
        >
            <Grid item xs={12} className="text-center">
                <img src={conclusionImage} alt={'Sucesso'}
                     className='img-success'/>

                <Typography variant="body2" className="titledesc">Proposta enviada<br/>com sucesso!</Typography>
            </Grid>

            <Grid item xs={12} className='text-center'>
                <Typography variant="body2" className="titlenew">Veja mais possibilidades<br/>de
                    empréstimo.</Typography>

                <AppButton
                    title='Ver mais'
                    onClick={() => navigate('/products/bb/available')}
                />
            </Grid>
        </Grid>
    );
}

export default ConclusionBB;
