import {FC, useState} from "react";
import "./CardLineCreditBB.scss";
import {ProductBB} from "../../../../models/products/BB/LineCreditBB";
import FormatMoney from "../../../../utils/FormatMoney";
import FormatDate from "../../../../utils/FormatDate";
import {useAuth} from "../../../../providers/AuthProvider";
import {Grid, Typography, Card, CardContent, Collapse} from "@mui/material";
import AppButton from "../../../AppButton/AppButton";

interface CardLineCreditBBProps {
    produto: ProductBB
    setShowSimulacao?: any;
}

const CardLineCreditBB: FC<CardLineCreditBBProps> = ({produto, setShowSimulacao}) => {
    const [open, setOpen] = useState<boolean>(false);
    const {userSimulation, setUserSimulation} = useAuth();

    const toggle = () => setOpen((current) => !current);

    const handleSimular = () => {
        setUserSimulation({...userSimulation, productBB: produto});
        setShowSimulacao(true);
    }

    return (
        <Grid container className="CardLineCreditBB" data-testid="CardLineCreditBB">
            <Card>
                <CardContent>
                    <Grid item xs={12} className="header">
                        <Typography variant="h4">{produto.convenio || produto.linhaCredito}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className="titleDest">Valor máximo liberado</Typography>
                        <Typography className="fieldValueDest">
                            {FormatMoney(produto.valorMaxEmprestimo)}
                        </Typography>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Typography className="title">Primeiro<br/>Vcto.</Typography>
                            <Typography className="fieldValue">
                                {FormatDate(produto.dataMinInicioParcela)}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography className="title">Valor Máx.<br/>Parcela</Typography>
                            <Typography className="fieldValue">
                                {FormatMoney(produto.valorMaxParcela)}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography className="title">Qtd. Máx.<br/>Parcelas</Typography>
                            <Typography className="fieldValue">
                                {produto.qtdeMaxParcela}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Collapse in={open}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography className="title">Valor<br/>Mínimo</Typography>
                                    <Typography className="fieldValue">
                                        {FormatMoney(produto.valorMinEmprestimo)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography className="title">Valor Mín.<br/>Parcela</Typography>
                                    <Typography className="fieldValue">
                                        {FormatMoney(produto.valorMinParcela)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography className="title">Qtd. Mín.<br/>Parcelas</Typography>
                                    <Typography className="fieldValue">
                                        {produto.qtdeMinParcela}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography className="title">Taxa Mín.<br/>Mensal</Typography>
                                    <Typography className="fieldValue">
                                        {`${produto.minTaxaJuros?.toFixed(2)}%`}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography className="title">Taxa Máx.<br/>Mensal</Typography>
                                    <Typography className="fieldValue">
                                        {`${produto?.maxTaxaJuros?.toFixed(2)}%`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} className="lineButton">
                        <AppButton
                            title={"CLIQUE AQUI E ACESSE ESSA OFERTA PARA SEGUIR COM A CONTRATAÇÃO E ALTERAR VALOR E/OU PARCELA"}
                            onClick={handleSimular}
                            textColor={'var(--blue-bb'}
                        />
                    </Grid>

                    <Grid item xs={12} className="text-center">
                        <a onClick={toggle}>
                            {open ? "Ver menos" : "Ver mais"}
                        </a>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
};

export default CardLineCreditBB;
