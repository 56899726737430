import {FC, useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import TimerProgress from '../../../../components/TimerProgress/TimerProgress';
import {useBB} from '../../../../providers/BBProvider';
import {LineCreditBB, ProductBB} from '../../../../models/products/BB/LineCreditBB';
import AppButton from '../../../../components/AppButton/AppButton';
import Loading from '../../../../components/Loading/Loading';
import Carousel from "react-multi-carousel";
import CardLineCreditoBB from '../../../../components/Products/BB/CardLineCreditoBB/CardLineCreditBB';
import './AvailableBB.scss';
import ModalSimulateBB from '../../../../components/Products/BB/ModalSimulateBB/ModalSimulateBB';

interface AvailableBBProps {
}

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 4
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

const AvailableBB: FC<AvailableBBProps> = () => {
    const [showSimulacao, setShowSimulacao] = useState<boolean>(false);
    const {produtos, getProducts, isLoading, setIsloading, timer, setTimer, bbAuth, erroCod, erro} = useBB();

    useEffect(() => {
        setTimer(600);
        setIsloading(true);

        if (bbAuth && Object.keys(bbAuth).length > 0) {
            getProducts();
        }
    }, [bbAuth]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            className="AvailableBB"
            data-testid="AvailableBB"
        >
            <Grid item xs={12}>
                <TimerProgress
                    missingTime={timer ?? 0}
                    color={"var(--yellow-bb)"}
                    backGroundColor={"var(--blue-bb)"}
                />
            </Grid>

            <Grid item xs={12} className={`wrapper ${erroCod ? 'h-100' : ''}`}>
                {!erroCod ? (
                    <>
                        {produtos?.map((line: LineCreditBB) => (
                            <Grid
                                item
                                xs={12}
                                justifyContent="center"
                                className="line"
                                key={line.lineCreditId}
                                paddingLeft={'15px'}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h3">{line?.lineCreditName}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Carousel
                                        responsive={responsive}
                                        showDots={true}
                                    >
                                        {line?.details?.map((item: ProductBB, key: number) => (
                                            <CardLineCreditoBB produto={item} setShowSimulacao={setShowSimulacao}
                                                               key={key}/>
                                        ))}
                                    </Carousel>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Grid item xs={12} justifyContent="center" className="text-center">
                        <Typography variant="h4">
                            {erro}
                        </Typography>

                        {erroCod !== 422 &&
                            <AppButton
                                title={"TENTAR NOVAMENTE"}
                                onClick={getProducts}
                            />
                        }
                    </Grid>
                )}
            </Grid>

            {isLoading && (
                <Loading message="Estamos buscando as<br/>melhores ofertas para você..."/>
            )}

            {showSimulacao && (<ModalSimulateBB show={showSimulacao} onClose={setShowSimulacao}/>)}
        </Grid>
    );
}

export default AvailableBB;
