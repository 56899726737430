import { FC, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import AppButton from '../../components/AppButton/AppButton';
import { ArrowRight } from '@mui/icons-material';
import { useStyle } from '../../providers/StyleProvider';
import './Login.scss';
import ReactInputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import FormatDate from '../../utils/FormatDate';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../providers/AuthProvider";
import { useBB } from "../../providers/BBProvider";
import MasterPage from "../../components/MasterPage/MasterPage";

interface LoginProps {
}

type FormValues = {
    cpf: string;
    birthday: string;
};

const Login: FC<LoginProps> = () => {
    const [isAceite, setIsAceite] = useState<boolean>(false);
    const [cpf, setCpf] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');

    const { primaryColor, loginText, secondaryColor } = useStyle();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const defaultValues = {
        cpf: '',
        birthday: '',
    } as FormValues;

    const { register, handleSubmit, control, setValue, reset, formState: { errors } } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { login, error, userSimulation, isLoading } = useAuth();
    const { getUrl } = useBB();

    const handleChange = (event: any) => {
        setIsAceite(event?.target?.checked);
    }

    const onSubmit = async (data: any) => {
        const _data = { ...data };
        _data.cpf = _data.cpf?.replace(/\D+/g, '');
        _data.birthday = moment(_data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
        await login(_data);
    }

    useEffect(() => {
        if (!error && !!userSimulation) {
            if (userSimulation?.produto?.sigla === 'BB') {
                getUrl().then();
            }
        }
    }, [error, userSimulation]);

    useEffect(() => {
        setValue('cpf', cpf?.replace(/\D+/g, '') || '');
    }, [cpf]);

    useEffect(() => {
        setValue('birthday', birthday || '');
    }, [birthday]);

    useEffect(() => {
        setCpf(urlParams.get('cpf') ?? '');
        setBirthday(moment(urlParams.get('birthday')).format('DD/MM/YYYY') ?? '');
        setIsAceite(false);
    }, []);

    return (
        <MasterPage>
            <Grid
                item
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                marginTop={'4rem'}
                className="Login"
                data-testid="Login"
            >
                {userSimulation?.guid ? (
                    <>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1"> {loginText}</Typography>
                            <Typography variant="subtitle1">Informe os seus dados para continuarmos.</Typography>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '30ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                marginTop={2}
                            >
                                <ReactInputMask
                                    mask="999.999.999-99"
                                    value={cpf}
                                    {...register('cpf')}
                                    onChange={(e: any) => {
                                        setCpf(e.target.value)
                                    }}
                                >
                                    <TextField
                                        name="cpf"
                                        label="CPF"
                                        variant="filled"
                                        color="error"
                                    />
                                </ReactInputMask>

                                <ReactInputMask
                                    mask="99/99/9999"
                                    value={birthday}
                                    {...register('birthday')}
                                    onChange={(e: any) => {
                                        setBirthday(e.target.value)
                                    }}
                                >
                                    <TextField
                                        name="birthday"
                                        label="Data de Nascimento"
                                        variant="filled"
                                        color="error"
                                    />
                                </ReactInputMask>

                                <FormGroup style={{ width: '80%', margin: '0 auto' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAceite}
                                                onChange={handleChange}
                                                sx={{
                                                    color: `${primaryColor}`,
                                                    '&.Mui-checked': {
                                                        color: secondaryColor //'#fff',
                                                    }
                                                }}
                                            />
                                        }
                                        label="Li e concordo com o Termo de Uso e Política de Privacidade"
                                    />
                                </FormGroup>
                            </Box>

                            {!!error &&
                                <Typography paddingTop='30px' variant={'h5'}>{error}</Typography>
                            }
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <AppButton
                                title={isLoading ? 'Aguarde...' : 'Começar'}
                                className='m-0'
                                disabled={!isAceite || isLoading}
                                icon={<ArrowRight fontSize='large' style={{ color: primaryColor }} />}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} alignSelf="flex-start">
                            <Typography variant='h6' marginTop='100px'>
                                Faça o acesso através do<br />link recebido.
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </MasterPage>
    );
}

export default Login;
