import React, { FC, useEffect } from 'react';
import './Splash.scss';
import MasterPage from "../../components/MasterPage/MasterPage";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useStyle } from "../../providers/StyleProvider";

interface SplashProps {
}

const Splash: FC<SplashProps> = () => {
    const { guid } = useParams<string>();
    const { setUserSimulation } = useAuth();
    const { handleStyle } = useStyle();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        handleStyle().then();
    }, []);

    useEffect(() => {
        if (!!guid) {
            localStorage.clear();
            setUserSimulation({ guid: guid });
            setTimeout(() => {
                if (urlParams.get('cpf') && urlParams.get('birthday')) {
                    navigate(`/login?cpf=${urlParams.get('cpf')}&birthday=${urlParams.get('birthday')}`);
                } else {
                    navigate('/login');
                }
            }, 2000);
        }
    }, [guid]);

    return (
        <MasterPage>
            <Grid
                className="Splash"
                data-testid="Splash"
                item
                xs={12}
                md={3}
                alignItems="center"
                justifyContent={'center'}
                flex={1}
                flexDirection="row"
                marginY="250px"
            >
                <Alert severity='success' className='mt'>Vamos começar...</Alert>
            </Grid>
        </MasterPage>
    )
};

export default Splash;
