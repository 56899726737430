import {FC, useEffect} from 'react';
import {Button, CircularProgress} from '@mui/material';
import './AppButton.scss';
import {useStyle} from '../../providers/StyleProvider';

interface AppButtonProps {
    title?: string,
    disabled?: boolean,
    ariaLabel?: string,
    icon?: JSX.Element,
    isLoading?: boolean,
    className?: string,
    onClick?: () => void,
    textColor?: string
}

const AppButton: FC<AppButtonProps> = ({
                                           title,
                                           onClick,
                                           disabled,
                                           className,
                                           ariaLabel,
                                           icon,
                                           isLoading = false,
                                           textColor
                                       }) => {
    const {primaryColor, secondaryColor} = useStyle();

    return (
        <Button
            variant="contained"
            disabled={disabled}
            className={`AppButton ${className}`}
            data-testid="AppButton"
            onClick={onClick}
            aria-label={ariaLabel || ''}
            component="span"
            endIcon={icon}
            style={{backgroundColor: secondaryColor}}
        >
            <>
                {isLoading ? (
                    <CircularProgress color="inherit" size={20}/>
                ) : (
                    <>
                        {title && (
                            <span style={{color: !!textColor ? textColor : primaryColor}}
                                  dangerouslySetInnerHTML={{__html: title}}></span>
                        )}
                    </>
                )}
            </>
        </Button>
    );
}

export default AppButton;
