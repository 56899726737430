import { Grid } from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import './TimerProgress.scss';

interface TimerProgressProps {
  missingTime: number;
  color?: string;
  backGroundColor?: string;
}

const TimerProgress: FC<TimerProgressProps> = ({ missingTime = 600, color, backGroundColor }) => {
  const [timerMax,] = useState<number>(600);
  const [counter, setCounter] = useState<number>(timerMax);
  const [intervalId,] = useState<any>();
  const [, setWidth] = useState<any>((timerMax - (timerMax - counter)) / timerMax * 100);
  const [root,] = useState<HTMLElement>(document.documentElement);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      clearInterval(intervalId);
    });
    return () => window.removeEventListener("popstate", () => {
      clearInterval(intervalId);
    });
  });

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    setWidth((timerMax - (timerMax - counter)) / timerMax * 100);
    root.style.setProperty('--border-timer', `${(timerMax - (timerMax - counter)) / timerMax * 100}%`);

    if (timer) {
      return () => clearInterval(timer);
    }

    if (counter === 0) {
      clearInterval(intervalId);
    }
  }, [counter]);

  useEffect(() => {
    setCounter(missingTime);
  }, [missingTime]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className="TimerProgress progress"
      data-testid="TimerProgress"
      style={{ borderColor: backGroundColor ? backGroundColor : "var(--primary)" }}
    >
      <Grid
        item
        xs={12}
        className={`progress-bar ${counter < timerMax && 'no-border'}`}
        role="progressbar"
        alignItems="center"
        style={{
          backgroundColor: backGroundColor ? backGroundColor : "var(--primary)"
        }}
      >
        {counter > 0 ? (
          <p
            className="justify-content-center d-flex position-absolute w-100"
            style={{ color: color ? color : "#fff" }}
          >
              Essa operação expira em {moment.utc(counter * 1000).format('mm:ss')}
          </p>
        ) : (
          <p
            className="justify-content-center d-flex position-absolute w-100"
            style={{ color: color ? color : "#fff" }}
          >
              Essa operação expirou
          </p>
        )}
      </Grid>
    </Grid>
  );
}

export default TimerProgress;
