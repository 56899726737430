import {useEffect, useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom"
import {useAuth} from "../providers/AuthProvider";

export const AuthenticationGate = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {userSimulation} = useAuth();
    const location = useLocation();

    useEffect(() => {
        const disposer = () => setTimeout(() => setIsLoading(false), 0);
        disposer();
    }, []);

    if (isLoading) {
        return <></>
    }

    if (!userSimulation?.guid || !userSimulation?.cpf || !userSimulation?.birthday) {
        return <Navigate to="/"/>
    }

    return (
        <div>
            <Outlet/>
        </div>
    )
}