import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import './Loading.scss';

interface LoadingProps {
  message?: string;
}

const Loading: FC<LoadingProps> = ({ message, ...rest }) => (
  <div className="Loading" data-testid="Loading" {...rest}>
        <div className="LoadingBody">
            <FontAwesomeIcon icon={faSpinner} spin/>
            {message && <div className="Content" dangerouslySetInnerHTML={{__html: message}}></div>}
        </div>
    </div>
);

export default Loading;
